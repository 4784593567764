import React from 'react';
import NFTCard from './NFTCard';
import styled from 'styled-components';

const NFTStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  max-height: 640px; // Ajuste de acordo com o tamanho desejado para duas linhas
  scrollbar-width: thin;
  scrollbar-color: #888 #222;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #222;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
  }
`;

const NFTContainer = ({ nfts }) => {
  return (
    <NFTStyle>
      {nfts.map((nft, index) => {
        return <NFTCard nft={nft} key={index} />;
      })}
    </NFTStyle>
  );
};



export default NFTContainer;
