import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import PageAdestramento from './PageAdestramento';
import Collection from './Colections/Collection';

const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: center;
  }
`;

const NavButton = styled.button`
  font-size: 0.8em;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: ${({ active }) => (active ? "#1b1b1b" : "transparent")};
  color: ${({ active }) => (active ? "#fff" : "#ffffff")};
  border: ${({ active }) => (active ? "none" : "1px solid #ffffff")};
  box-shadow: 1px 1px 0px 1px #1b1b1b;
  cursor: pointer;
  z-index: 10000;
  margin-left: 10px;
  position: relative;

  @media (max-width: 768px) {
    font-size: 0.6em;
    padding: 2px
    display: flex;
    margin-bottom: 7px;
  }
`;

function Menu() {
  return (
    <BrowserRouter style={{ zIndex: 5 }}>
      <Nav>
        <Link to="/">
          <NavButton>Home</NavButton>
        </Link>

        {/* <Link to="/adestramento">
          <NavButton>Adestramento</NavButton>
        </Link> */}

        <Link to="/colecoes">
          <NavButton>Collection</NavButton>
        </Link>
        <Link to="https://viralata.finance/en">
          <NavButton>REAU (Token)</NavButton>
        </Link>
        <Link to="https://opensea.io/collection/viralatafunkcamarotebonde">
          <NavButton>OpenSea</NavButton>
        </Link>
        <Link to="https://viralatafunk.com/atributos">
          <NavButton>Attributes</NavButton>
        </Link>
        <Link to="https://app.viralata.finance/">
          <NavButton>ViralataSwap</NavButton>
        </Link>
        <Link to="https://viralata.com.vc/en">
          <NavButton>Viralata Ecosystem</NavButton>
        </Link>
      </Nav>
      <Routes>
        <Route path="/" element={<a href="https://viralatafunk.com"></a>} />
        <Route path="/adestramento" element={<PageAdestramento />} />
        <Route path="/colecoes" element={<Collection />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Menu;
