import React, { useState } from "react";
import Mint from "./mint";
import styled from "styled-components";
import PaginaUm from "./Tarefa1/PaginaUm"
import PaginaDois from "./Tarefa1/PaginaDois"

const PainelDois = styled.div`
  color: white;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const BotaoPagina = styled.button`
  position: absolute;
  bottom: 20px;
  font-size: 0.8em;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  border: none;
  box-shadow: 1px 1px 0px 1px #1b1b1b;
`;

const BotaoPaginaAnterior = styled(BotaoPagina)`
  left: 20px;
`;

const BotaoProximaPagina = styled(BotaoPagina)`
  right: 20px;
`;

function Tarefa() {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const isLastPage = currentPage === 3; // Altere 3 para o número de páginas desejado.

  let pageContent;
  switch(currentPage) {
    case 1:
      pageContent = <PaginaUm />;
      break;
    case 2:
      pageContent = <PaginaDois />;
      break;
    // adicione mais cases para cada página necessária
    default:
      pageContent = null;
  }

  return (
    <>
      <PainelDois>
        <p>Página {currentPage}</p>
      </PainelDois>
      <BotaoPaginaAnterior onClick={handlePrevClick} disabled={currentPage === 1}>
        Anterior
      </BotaoPaginaAnterior>
      <BotaoProximaPagina onClick={handleNextClick} disabled={isLastPage}>
        Próxima
      </BotaoProximaPagina>
      {pageContent}
      {isLastPage && <Mint />}
    </>
  );
}


export default Tarefa;
