import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: black;
`;

const Boxtexto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: black;
  background-color: white;
  border-radius: 10px;
  font-family: arial;
  width: 70%;
  padding: 20px;
`;

const Imagem = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
`;

function PaginaUm() {
  return (
    <Container>
        <Imagem src="https://pbs.twimg.com/media/FsvDqPjWwAEsTPz.jpg" />
        <Boxtexto>
      <p>A história da REAU começa com a publicação do contrato do token em 21/03/2021, na BSC (Binance Smart Chain) por alguns anônimos que discutiam sobre a falta de uma criptomoeda do vira-lata caramelo - já que o governo não quis colocar o nosso querido catiorro na nota de R$200, então nada melhor do que a internet brasileira criar sua própria moeda do caramelo, não é mesmo?  </p>
        <br></br>
      <p>Foi assim que surgiu a Viralata Finance - a criptomoeda meme brasileira do vira-lata caramelo - cuja sigla foi carinhosamente nomeada de $REAU.</p>
      <br></br>
      <p>Outro detalhe importante de ressaltar aqui é que esse surgimento não foi organizado por uma empresa ou organização central. A $REAU surgiu do mais puro suco da internet brasileira. Surgiu do meme, do espírito HUEHUEbr e foi criando seguidores e uma comunidade incrível que sobrevive até hoje. Uma comunidade autônoma e descentralizada.</p>
    </Boxtexto>
    </Container>
  );
}

export default PaginaUm;
