import React, { useState, useEffect } from "react";
import Web3 from "web3";

function Supply() {
  const [totalSupply, setTotalSupply] = useState(null);

  useEffect(() => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://eth-mainnet.alchemyapi.io/v2/yh6GEP8LHp8bduFX2H2H4BYFvFNEDUs7"
      )
    );

    const nftContract = new web3.eth.Contract(
      [
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
      ],
      "0x62D862dF5c57fa6A8910B0EB96e2a143Fd2db19C"
    );

    nftContract.methods.totalSupply().call().then(setTotalSupply);
  }, []);

  return (
    <div>
      {totalSupply === null ? (
        <p>Loading...</p>
      ) : (
        <p>{totalSupply}</p>
      )}
    </div>
  );
}

export default Supply;
