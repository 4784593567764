import React from "react";
import styled from "styled-components";
import Mint from "./mint"

const StyledPainel = styled.div`
  background-image: linear-gradient(rgb(87 248 255 / 79%), rgb(169 0 255 / 35%));
  box-shadow: -3px 3px 0px 1px #1b1b1b;
  border-radius: 5px;
  color: #000;
  flex-flow: column;
  font-size: 2.5em;
  border-radius: 15px;
  text-align: center;
  max-width: 300px;
  max-height: 300px;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  min-width: 300px;
  min-height: 300px;
  align-items: center;
`;

function Painel() {
  return (
    <>
    <div style={{marginTop: "-30px", display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
        <StyledPainel style={{marginTop: '20px'}}>
            <img style={{borderRadius: '15px'}}src={`${process.env.PUBLIC_URL}/config/images/viralata2.gif`} alt="Example Image" />
        </StyledPainel>
        <StyledPainel style={{marginTop: '20px'}}>
            <Mint/>
      </StyledPainel>
    </div>
    <br></br>
    <div style={{marginTop: "15px", display: "flex", justifyContent: "center"}}>
    <a href="https://viralatafunk.com">
  <img style={{margin: "auto"}} src="/config/images/usa.png" alt="USA"/>
</a>
<br></br>🔄
<a href="https://br.viralatafunk.com">
  <img style={{margin: "auto"}} src="/config/images/brsmall.png" alt="USA"/>
</a>
</div>

    </>
  );
}

export default Painel;
