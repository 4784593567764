import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const NFTWrapper = styled.div`
  margin: 20px;
  text-align: left;
  color: white;
  perspective: 1000px;
`;

const Card = styled.div`
  position: relative;
  width: 200px;
  height: 300px;
  border-radius: 15px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0 0 50px rgba(0, 0, 0, 0.2);
  transform-style: preserve-3d;
  transition: 0.5s;

  &:hover {
    transform: rotateY(20deg);
  }
`;

const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 100%;
    height: 70%;
    object-fit: cover;
  }

  .attributes {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const ModalBackground = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 10px;
  z-index: 1001;
  text-align: center;
  color: white;
  perspective: 1000px;
  height: 100%;

  .attribute-table {
    border-collapse: collapse;
    width: 80%;
    margin: auto;
  }
  
  .attribute-table td {
    border: 1px solid #ddd;
    padding: 3px;
    font-size: 13px;
    text-align: center;
  }
  
  .attribute-table tr:nth-child(even) {
    background-color: #f2f2f242;
  }
  
  .cartao {
    background-image: linear-gradient(rgb(87 248 255),rgb(81 0 122));
    position: relative;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: -3px 3px 0 1px #ffffff;
    transform-style: preserve-3d;
    transition: 0.5s;
    margin: auto;
    font-color: white;
    width: 330px;

    &:hover {
      transform: rotateY(25deg) rotateX(5deg);
      box-shadow: -3px 3px 0 1px #ffffff;
    }
  }

  .number-3d {
    position: absolute;
    top: 15px;
    right: 18px;
    font-size: 15px;
    box-shadow: 2px 2px 0 rgb(0 0 0 / 0%), 4px 4px 0 rgba(0,0,0,0.1), 6px 6px 0 rgba(0,0,0,0.1), 8px 8px 0 rgba(0,0,0,0.1);
    color: #383232;
    background: white;
    border-radius: 15px;
    padding: 7px;
  }
`;


const NFTCard = ({ nft, key }) => {
  

  return (
<div>
    {nft.contract}
  </div>
  )
}

export default NFTCard;
