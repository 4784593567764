import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import * as s from "../../styles/globalStyles";
import NFTContainer from "./NFTContainer";

const BoxAdestramento = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: purple;
  border-radius: 10px;
  width: 90%;
  height: 90%;
  z-index: 2;
`;

export const StyledButton = styled.button`
  padding: 20px;
  border-radius: 50px;
  border: none;
  font-size: 20px;
  background-color: #6488cb;
  padding: 10px;
  font-weight: bold;
  color: #FFFFFF;
  width: 200px;
  cursor: pointer;
  box-shadow: -3px 3px 0px 1px #1b1b1b;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  font-size: 1.2rem;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  border: none;
  box-shadow: 1px 1px 0px 1px #1b1b1b;
  transition: all 0.2s ease-in-out;
  margin-bottom: 5px;
  width: 200px;
  opacity: 10%;

  &:hover {
    background-color: #000;
    color: #fff;
    transform: scale(1.1);
  }
`;

const truncateWalletAddress = (address) => {
  if (!address || address.length < 10) {
    return address;
  }
  const firstFive = address.slice(0, 5);
  const lastThree = address.slice(-3);
  return `${firstFive}...${lastThree}`;
};



function Collection() {
  const [walletAddress, setWalletAddress] = useState(null)
  const [nfts, setNfts] = useState([])
  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setWalletAddress(accounts[0])

    }
  }

  const getNftData = async () => {

    if(!walletAddress) return;
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-API-KEY': '5d36e26a-655e-4c5c-91e3-bea4c0a24bde'
      }
    };
    const response = await fetch(`https://api.rarible.org/v0.1/items/byOwner?owner=ETHEREUM:${walletAddress}`, options)

    const data = await response.json()

    setNfts(data.items)
  }


  useEffect(() => {
    getNftData()
  }, [walletAddress])

  return (
    <BoxAdestramento>
          <div className="Collection">
    <div style={{marginTop: '10px', marginBottom: '10px', color: 'white'}}>
    Account: {truncateWalletAddress(walletAddress)}
    </div>
    <StyledButton style={{marginBottom: '15px'}} onClick={connectWallet}>
      Conectar
    </StyledButton>
  </div>
  <NFTContainer nfts={nfts}/>
    </BoxAdestramento>
  );
}



export default Collection;

