import React, { useState } from "react";
import Tarefa from "./Tarefa";
import styled from 'styled-components';
import * as s from "./styles/globalStyles";

const BoxAdestramento = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: purple;
  border-radius: 10px;
  width: 90%;
  height: 90%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  font-size: 1.2rem;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  border: none;
  box-shadow: 1px 1px 0px 1px #1b1b1b;
  transition: all 0.2s ease-in-out;
  margin-bottom: 5px;
  width: 200px;

  &:hover {
    background-color: #000;
    color: #fff;
    transform: scale(1.1);
  }
`;

function PageAdestramento() {
  const [isTarefa1Selected, setIsTarefa1Selected] = useState(false);
  const [isTarefa2Selected, setIsTarefa2Selected] = useState(false);
  const [isTarefa3Selected, setIsTarefa3Selected] = useState(false);
  const [areButtonsVisible, setAreButtonsVisible] = useState(true);

  const handleTarefa1Click = () => {
    setIsTarefa1Selected(true);
    setIsTarefa2Selected(false);
    setIsTarefa3Selected(false);
    setAreButtonsVisible(false);
  };

  const handleTarefa2Click = () => {
    setIsTarefa1Selected(false);
    setIsTarefa2Selected(true);
    setIsTarefa3Selected(false);
    setAreButtonsVisible(false);
  };

  const handleTarefa3Click = () => {
    setIsTarefa1Selected(false);
    setIsTarefa2Selected(false);
    setIsTarefa3Selected(true);
    setAreButtonsVisible(false);
  };

  return (
    <BoxAdestramento>
    <s.SpacerSmall />
      <s.TextTitle>
        Adestramento <span role="img" aria-label="patinha de cachorro">🐾</span>
    </s.TextTitle>
    <s.SpacerMedium />
      <div>
        {areButtonsVisible && (
          <Nav>
              <Button onClick={handleTarefa1Click}>Tarefa 1</Button>
              <Button onClick={handleTarefa2Click}>Tarefa 2</Button>
              <Button onClick={handleTarefa3Click}>Tarefa 3</Button>
          </Nav>
        )}
        {isTarefa1Selected && <Tarefa tarefa="tarefa1" />}
        {isTarefa2Selected && <Tarefa tarefa="tarefa2" />}
        {isTarefa3Selected && <Tarefa tarefa="tarefa3" />}
      </div>
    </BoxAdestramento>
  );
}



export default PageAdestramento;
