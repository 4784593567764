import React, { useState } from "react";
import styled from "styled-components";
import Menu from "./Components/menu";
import PageAdestramento from "./Components/PageAdestramento";
import Painel from "./Components/Painel";
import SocialMediaLinks from "./Components/SocialMediaLinks";
import * as s from "./styles/globalStyles";

const LogoContainer = styled.div`
  align-items: center;
  max-width: 500px;
  margin: auto;

  @media (max-width: 700px) {
    max-width: 500px;
  }
`;

function App() {
  const [isPageAdestramentoSelected, setIsPageAdestramentoSelected] = useState(false);

  return (
    <div style={{ zIndex: "10000", position: "relative" }}>
      <div style={{ marginBottom: "20px" }}>
        <Menu setIsPageAdestramentoSelected={setIsPageAdestramentoSelected} />
      </div>
      <div style={{ marginBottom: "20px" }}>
        {isPageAdestramentoSelected && <PageAdestramento />}
      </div>
      <LogoContainer>
            <img src={`${process.env.PUBLIC_URL}/config/images/titulo20232.png`} alt="Logo" />
        </LogoContainer>
      <div style={{ marginBottom: "20px" }}>
        <>
          <s.SpacerLarge />
          <h2 style={{ margin: 'auto', maxWidth: '600px', color: 'white', lineHeight: 'initial' }}>
            Cards and Neymar's monkey?! Things of the past. Now the ViralataFunks have arrived to replace them and show how much a truly rare collection worth. 💎✊🥇
          </h2>
        </>
        <s.SpacerMedium />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Painel />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <SocialMediaLinks />
      </div>
    </div>
  );
}

export default App;
