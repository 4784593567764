import React from 'react';

function SocialMediaLinks() {
    return (
      <>
        <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css' />
        <div className="wrapper">
          <a href="https://twitter.com/viralatafunk" target="blank" className="icon twitter">
            <div className="tooltip">Twitter</div>
            <span><i className="fab fa-twitter"></i></span>
          </a>
          <a href="https://instagram.com/viralatafunk" target="blank" className="icon instagram">
            <div className="tooltip">Instagram</div>
            <span><i className="fab fa-instagram"></i></span>
          </a>
          <a href="https://discord.gg/MHpmCjS6hw" target="blank" className="icon discord">
            <div className="tooltip">Discord</div>
            <span><i className="fab fa-discord"></i></span>
          </a>
          <a href="https://t.me/viralatafinance" target="blank" className="icon telegram">
            <div className="tooltip">Telegram</div>
            <span><i className="fab fa-telegram"></i></span>
          </a>
          <a href="https://tiktok.com/@viralatafunk" target="blank" className="icon telegram">
            <div className="tooltip">TikTok</div>
            <span><i className="fab fa-tiktok"></i></span>
          </a>
        </div>
      </>
    );
  }
  
  export default SocialMediaLinks;