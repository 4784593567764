import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Imagem = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
`;

function PaginaUm() {
  return (
    <Container>
      <p>Aqui seria a página dois sem foto</p>
    </Container>
  );
}

export default PaginaUm;
